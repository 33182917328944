<template>
    <FormWrapper>
        <PageTitle title="Add/Update Definition" slot="title" link="/helpContent/Definition Detail" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-3" >
                    <FormSelect label="Definition Group" :items="definitionList" item-name="Name" item-value="id"  v-model="form.selectedDefinition" :style="{pointerEvents: display}" rules="required" />
                </div>
                <div class="col-sm-3">
                    <InputText v-model="form.name" rules="required" label="Definition Name"  />
                </div>
                <div class="col-sm-4">
                    <InputText v-model="form.text" label="Text"  />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getAllParentDefinition, addUpdateDefinition, getDefinition} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "AddUpdate",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            ValidationObserver
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    name:'',
                    text:''
                },
                display:'initial',
                definitionList: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const data = {
                            definitionId: atob(this.$route.params.id)
                        };
                        getDefinition(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
                getAllParentDefinition().then((res)=>{
                    this.definitionList=res.data;
                })
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            definitionId: this.$route.params.id?atob(this.$route.params.id):0,
                            parentId: this.$route.params.parent_id?this.$route.params.parent_id:this.form.selectedDefinition,
                            definitionName: this.form.name,
                            text: this.form.text
                        };
                        addUpdateDefinition(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.name = response.data[0].Name;
                this.form.text = response.data[0].Text;
                this.form.selectedDefinition = response.data[0].Parent_id;
                this.display = 'none !important';
            },
        }
    }
</script>
<style lang="scss">
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>